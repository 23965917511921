<template>
    <div class="waybillRepositoryPage">
        <div class="facilityBox">
            <!-- 顶部按钮组 -->
            <div style="display: flex; justify-content: space-between;">
                <el-form ref="searchForm" inline :model="searchForm">
                    <el-form-item label="待开票申请编号" prop="importBatchID">
                        <el-input v-model="searchForm.importBatchID" placeholder="请输入待开票申请编号"></el-input>
                    </el-form-item>
                    <el-form-item label="货主单位" prop="EnterpriseNamme">
                        <el-input v-model="searchForm.EnterpriseNamme" placeholder="请输入货主单位"></el-input>
                    </el-form-item>
                    <el-form-item label="业务类型" prop="InvoiceType">
                        <el-select v-model="searchForm.TaskTypeID" placeholder="请选择业务类型" clearable @clear="changeClear('1')"
                            @change="changetask($event)">
                            <el-option v-for="item in tasklist" :key="item.TaskTypeID" :label="item.TaskTypeName"
                                :value="item.TaskTypeID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="场景类型" prop="TaskSceneTypeID">
                        <el-select v-model="searchForm.sceneID" clearable placeholder="请先选择  “业务类型”"
                            @visible-change="changeValue($event)" @clear="changeClear('2')" @change="search()">
                            <el-option v-for="item in scenelist" :key="item.TaskSceneTypeID" :label="item.TaskSceneName"
                                :value="item.TaskSceneTypeID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" icon="el-icon-zoom-in" @click="search">搜索</el-button>
                    <el-button type="primary" icon="el-icon-delete" @click="$refs['searchForm'].resetFields()">清除
                    </el-button>

                </el-form>
            </div>
            <!-- 运单库表格 -->
            <el-table :data="tableList1" v-loading="pagination1.loading"
                :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
                <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="ImportBatchID" label="待开票申请编号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseNamme" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Remark" label="备注" width="200">
                    <template slot-scope="scope" width="200">
                        <div>{{ scope.row.Remark ? scope.row.Remark : '暂无备注' }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="总计运单数量" width="200">
                    <template slot-scope="scope">
                        <div style="cursor: pointer; display: inline-block">
                            <el-tag @click="openAllWaybill(scope.row)">{{ scope.row.WayBillCount }}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="正确运单数量" width="200">
                    <template slot-scope="scope">
                        <div style="cursor: pointer; display: inline-block">
                            <el-tag type="success" @click="openRightWaybill(scope.row)">{{ scope.row.CorrectBillCount }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="错误运单数量" width="200">
                    <template slot-scope="scope">
                        <div style="cursor: pointer; display: inline-block">
                            <el-tag type="danger" @click="openErrorWaybill(scope.row)">{{ scope.row.ErrorBillCount }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="审核中运单数量" width="200">
                    <template slot-scope="scope">
                        <div style="cursor: pointer; display: inline-block">
                            <el-tag type="info" @click="openAutioInfo(scope.row)">{{ scope.row.AuditBillCount }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" :label="searchForm.sceneID == 7 ? '总工单费' : '总运费'"
                    width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CorrectAmount" :label="searchForm.sceneID == 7 ? '正确运单工单费' : '正确运单运费'"
                    width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.CorrectAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ImportTime" label="上传/撤回时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="UpdateTime" label="更新时间" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <!-- 运单库表格分页 -->
            <el-pagination background style="margin-top: 16px;" @current-change="pagination1Change"
                :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
                layout="total, prev, pager, next, jumper" :total="pagination1.total">
            </el-pagination>
        </div>
        <!-- 所有运单dialog -->
        <el-dialog width="1200px" :visible.sync="flag.allWaybillDialog" append-to-body>
            <div style="display:flex;align-items:center">
                <el-form ref="searchDriverForm" inline :model="searchDriverForm" size="mini">
                    <el-form-item label="司机姓名" prop="driverName">
                        <el-input v-model="searchDriverForm.driverName" placeholder="请输入司机姓名" style="width:150px">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="driverPhone">
                        <el-input v-model="searchDriverForm.driverPhone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="driverIdCard">
                        <el-input v-model="searchDriverForm.driverIdCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-button type="primary" size="mini" icon="el-icon-zoom-in" @click="openAllWaybill(currentItem)">搜索
                    </el-button>
                    <el-button type="primary" size="mini" icon="el-icon-delete"
                        @click="$refs['searchDriverForm'].resetFields()">
                        清除
                    </el-button>
                    <el-button style="margin-bottom: 16px;" icon="el-icon-download" size="mini" type="primary"
                        @click="downloadWaybill(null)">下载运单
                    </el-button>
                </el-form>
                <!-- 下载按钮 -->
            </div>
            <!-- 表格信息 -->
            <el-table style="width: 100%" height="500" :data="currentAllWaybillList"
                v-loading="pagination2.loading || flag.dialogLoading" @select="currentAllWaybillSelectedChange"
                @select-all="currentAllWaybillSelectedChange" ref="allWaybillRef"
                :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-if="searchForm.sceneID == 7">
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskScenceTypeName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="操作员姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="操作员手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="操作员身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="工程设备名称" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.CarNumber }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.OriginAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.ArriveAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="操作员装货数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="操作员运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.DriverCarryPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="工单费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.CarriageTotalPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.ExpensesOfTaxation | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeAmount" label="工单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.PayeeAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <el-table style="width: 100%" height="500" :data="currentAllWaybillList"
                v-loading="pagination2.loading || flag.dialogLoading" @select="currentAllWaybillSelectedChange"
                @select-all="currentAllWaybillSelectedChange" ref="allWaybillRef"
                :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.LicensePlate }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Start_addrees }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Arrivals }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Driver_transportation_price | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="amount_count" label="运费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.amount_count | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Taxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTaxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination background style="margin-top: 16px;" @current-change="pagination2Change"
                :current-page.sync="pagination2.page" :page-size="pagination2.pagesize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" @size-change="pagination2SizeChange"
                layout="total, sizes,prev, pager, next, jumper" :total="pagination2.total">
            </el-pagination>
        </el-dialog>

        <!-- 正确运单dialog -->
        <el-dialog width="1200px" :visible.sync="flag.rightWaybillDialog" append-to-body>
            <!-- 温馨提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
                <span slot="title">
                    <span>温馨提示：</span><br />
                    <span>风控时间：{{ riskTime }}</span><br />
                    <span>风控结果：正确{{ rightWaybillList.length }}条，已加入正确运单中</span><br />
                    <span>勾选/全选所需运单，点击“开启流程”，则对这批运单开启作业</span><br />
                </span>
            </el-alert>
            <!-- 下载按钮 -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 16px;">
                <el-button type="primary" @click="downloadWaybill(1)">下载所有正确运单</el-button>
            </div>
            <!-- 表格信息 -->
            <el-table style="width: 100%" height="400" :data="currentRightWaybillList"
                v-loading="pagination3.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
                @select="currentRightWaybillSelectedChange" @select-all="currentRightWaybillSelectedChange"
                ref="rightWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-if="searchForm.sceneID == 7">
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskScenceTypeName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="操作员姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="操作员手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="操作员身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="工程设备名称" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.CarNumber }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.OriginAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.ArriveAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="操作员装货数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="操作员运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.DriverCarryPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="工单费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.CarriageTotalPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.ExpensesOfTaxation | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeAmount" label="工单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.PayeeAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <el-table style="width: 100%" height="400" :data="currentRightWaybillList"
                v-loading="pagination3.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
                @select="currentRightWaybillSelectedChange" @select-all="currentRightWaybillSelectedChange"
                ref="rightWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.LicensePlate }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Start_addrees }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Arrivals }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Driver_transportation_price | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="amount_count" label="运费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.amount_count | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Taxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTaxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination background style="margin-top: 16px;" @current-change="pagination3Change"
                :current-page.sync="pagination3.page" :page-size="pagination3.pagesize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" @size-change="pagination3SizeChange"
                layout="total, sizes,prev, pager, next, jumper" :total="pagination3.total">
            </el-pagination>
            <!-- 当前组合 -->
            <div style="margin: 16px 0 0 25vw;" v-if="searchForm.sceneID == 7">
                <span style="display:inline-block; ; width: 120px">工单费：{{ currentRightWaybillAmount }}</span>
                <span style="display:inline-block; ; width: 120px">运单数量：{{ currentRightWaybillSelectedCount }}</span>
            </div>
            <div style="margin: 16px 0 0 25vw;" v-else>
                <span style="display:inline-block; ; width: 120px">运费：{{ currentRightWaybillAmount }}</span>
                <span style="display:inline-block; ; width: 120px">运单数量：{{ currentRightWaybillSelectedCount }}</span>
            </div>
        </el-dialog>

        <!-- 错误运单dialog -->
        <el-dialog width="1200px" :visible.sync="flag.errorWaybillDialog" append-to-body>
            <!-- 温馨提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
                <span slot="title">
                    <span>温馨提示：</span><br />
                    <span>风控时间：{{ riskTime }}</span><br />
                    <span>风控结果：错误{{ pagination4.total }}条，全部在下列错误运单表中，请修正</span><br />
                </span>
            </el-alert>
            <div style="display:flex; justify-content: space-between">
                <div>
                    <el-button style="margin-bottom: 16px;" type="primary" @click="downloadWaybill(2)">下载错误运单
                    </el-button>
                </div>
            </div>
            <!-- 表格信息 -->
            <el-table style="width: 100%" height="400" :data="currentErrorWaybillList"
                v-loading="pagination4.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
                @select="currentErrorWaybillSelectedChange" @select-all="currentErrorWaybillSelectedChange"
                ref="errorWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-if="searchForm.sceneID == 7">
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="ErrorMsg" label="错误信息" width="200" fixed="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskScenceTypeName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="操作员姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="操作员手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="操作员身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="工程设备名称" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.CarNumber }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.OriginAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.ArriveAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="操作员装货数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="操作员运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.DriverCarryPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="工单费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.CarriageTotalPrice | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.ExpensesOfTaxation | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeAmount" label="工单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.PayeeAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <el-table style="width: 100%" height="400" :data="currentErrorWaybillList"
                v-loading="pagination4.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
                @select="currentErrorWaybillSelectedChange" @select-all="currentErrorWaybillSelectedChange"
                ref="errorWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
                <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="ErrorMsg" label="错误信息" width="200" fixed="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.PayeeUserID)">
                            {{ scope.row.PayeeIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.LeaderUserID)">
                            {{ scope.row.LeaderIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
                            {{ scope.row.LicensePlate }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Start_addrees }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
                    <template slot-scope="scope">
                        <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
                            {{ scope.row.Arrivals }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Driver_transportation_price | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="amount_count" label="运费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.amount_count | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Taxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTaxes | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
                    v-if="$entrustSceneIds.includes(Number(searchForm.sceneID))">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.EntrustedTotalAmount | formatMoney }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column align="center" prop="" label="开票金额" width="200" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background style="margin-top: 16px;" @current-change="pagination4Change"
                :current-page.sync="pagination4.page" :page-size="pagination4.pagesize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" @size-change="pagination4SizeChange"
                layout="total,sizes, prev, pager, next, jumper" :total="pagination4.total">
            </el-pagination>
        </el-dialog>

        <!-- 审核中运单dialog -->
        <el-dialog width="600px" :visible.sync="flag.auditWaybillDialog" append-to-body>
            <div style="display: flex; align-items: center; flex-direction: column">
                <div style="width: 300px; display: flex; flex-direction: column; align-items: center; margin-bottom: 32px;">
                    <strong style="margin-bottom: 16px; font-size: 18px;">温馨提示</strong>
                    <p style="font-size: 15px;">提交时间：{{ auditInfo.CommitTime }}</p>
                    <p style="font-size: 15px;">提交数量：{{ auditInfo.CommitNum }}</p>
                </div>
                <div style="width: 300px; display: flex; flex-direction: column; align-items: center; margin-bottom: 32px;">
                    <strong style="margin-bottom: 16px; font-size: 18px;">人工审核结果</strong>
                    <p style="font-size: 15px;">正确<span style="font-weight: 600; color: #67C23A">{{ auditInfo.CorrectNum
                    }}</span>条，已在正确运单中</p>
                    <p style="font-size: 15px;">错误<span style="font-weight: 600; color: #F56C6C">{{ auditInfo.ErrorNum
                    }}</span>条，已在错误运单中</p>
                    <p style="font-size: 15px;">审核中<span style="font-weight: 600; color: #E6A23C">{{
                        auditInfo.AuditingNum
                    }}</span>条，请继续等待结果</p>
                    <p style="margin-top: 30px;font-weight: bold;">审核服务商：{{ auditInfo.ProviderName }}</p>
                </div>
            </div>
        </el-dialog>


        <!-- 地图组件dialog -->
        <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
            <!-- 地图组件 -->
            <TXmap style="height:600px;" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
        </el-dialog>

        <!-- 车辆信息dialog -->
        <el-dialog width="1300px" :visible.sync="flag.showVehicleInfo" append-to-body>
            <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
        </el-dialog>

        <!-- 操作员信息组件 -->
        <el-dialog width="1300px" :visible.sync="flag.showDriverInfo" append-to-body>
            <DriverInfo :driverInfo="driverInfo" />
        </el-dialog>
    </div>
</template>

<script>
import { sumAuditInfo, sumMoney, getBillList, gillsInfoDetails, downtBills } from '@/api/waybill/waybillManage/waybillRepository'
import { getMapPath, getCarInfo, getDriverDetails } from '@/api/common/common'
import { getTask, getTaskScene } from '@/api/goodsOwner/goodsOwnerManage/basicInfo'
import TXmap from '@/components/commonCmpt/TXmap'
import VehicleInfo from '@/components/businessCmpt/vehicleInfo'
import DriverInfo from '@/components/businessCmpt/driverInfo'
export default {
    data() {
        return {
            flag: {
                allWaybillDialog: false, //所有运单详情dialog
                rightWaybillDialog: false, //正确运单详情dialog
                errorWaybillDialog: false, //错误运单详情dialog
                auditWaybillDialog: false, //审核中运单详情dialog
                editWaybillDialog: false, //可编辑运单dialog
                updateRemarkDialog: false, //备注编辑dialog
                voucherDialog: false, //凭证dialog
                openVoucherDialogIng: false, //打开凭证dialog中
                voucherTabActive: '', //当前激活凭证的索引
                voucherTabChildActive: '', //当前激活凭证的索引
                voucherTabChildActive: '', //当前激活凭证的索引
                riskVerifying: false, //风控校验中
                riskProgress: 0, //风控进度
                showMap: false, //显示地图
                showVehicleInfo: false, //显示车辆信息
                showDriverInfo: false, //显示操作员信息
                uploadvouchering: false, //上传凭证中
                dialogLoading: false, //dialog弹窗是否是loading状态
                uploadErrorDialog: false, //上传运单产生的错误信息dialog
                importTraceAuth: false, // 导入轨迹权限
            },
            searchForm: {
                importBatchID: '', //待结算单号
                TaskTypeID: '',
                sceneID: '',
                EnterpriseNamme: ''
            },
            searchDriverForm: {
                driverIdCard: '',
                driverPhone: '',
                driverName: ''
            },
            pagination1: { //运单库
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination2: { //所有运单
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination3: { //正确运单
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination4: { //错误运单
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination6: { //可编辑运单
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination7: { //凭证列表
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            pagination8: { //回收站列表
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
            },
            uploadErrorList: [], //上传运单产生的错误信息
            tableList1: [], //表格数据
            allWaybillList: [], //所有运单
            currentAllWaybillSelectedList: [], //当前勾选的所有运单二维数组
            rightWaybillList: [], //正确的运单列表
            auditInfo: {}, //审核信息
            currentRightWaybillSelectedList: [], //当前勾选的正确运单二维数组
            currentRightWaybillSelectedCount: 0, //当前勾选的正确运单数量
            currentRightWaybillAmount: 0, //当前勾选的正确运单的工单费
            errorWaybillList: [], //错误的运单列表
            currentErrorWaybillSelectedList: [], //当前勾选的错误运单二维数组
            auditWaybillList: [], //审核中的运单列表
            editWaybillList: [], //在线编辑的运单
            currentEditWaybillSelectedList: [], //当前勾选的正确运单二维数组
            riskResultList: [], //风控结果运单列表
            currentItem: {}, //当前续传所操作的对象
            vehicleInfo: {}, //车辆信息
            driverInfo: {}, //操作员信息
            mapList: [], //轨迹列表
            riskTime: '', //过风控时间
            voucherList: [], //凭证二级列表
            currentVoucherObj: {}, //当前操作的凭证二级对象
            supplierSelected: '',//选中的服务商
            supplierList: [], // 服务商列表
            // 获取业务类型
            tasklist: [
                {
                    TaskTypeName: '全部',
                    TaskTypeID: ''
                }
            ],
            // 场景类型
            scenelist: [],
        }
    },
    methods: {
        //搜索
        search() {
            this.pagination1.page = 1
            this.getBillList()
        },
        // 业务类型
        changetask(e) {
            if (e == '') {
                this.scenelist = [
                    {
                        TaskSceneName: '全部',
                        TaskSceneTypeID: ''
                    }
                ]
                this.tableList1 = []
                this.search()
            }
            this.searchForm.sceneID = ''
        },
        // 业务类型
        changeClear(type) {
            if (type == 1) {
                this.searchForm.TaskTypeID = ''
                this.searchForm.sceneID = ''
            } else {
                this.searchForm.sceneID = ''
            }
            this.search()
        },
        // 场景类型
        changeValue(e) {
            if (e) {
                if (this.searchForm.TaskTypeID == '') return;
                // 获取场景类型
                getTaskScene({ TaskTypeID: this.searchForm.TaskTypeID }).then(res => {
                    let arr = [
                        {
                            TaskSceneName: '全部',
                            TaskSceneTypeID: ''
                        }
                    ]
                    arr = arr.concat(res.TaskTypeList)
                    this.scenelist = arr
                })
            }
        },
        //分页回调
        pagination1Change(e) {
            this.pagination1.page = e
            this.getBillList()
        },
        //所有运单分页回调
        pagination2Change(e) {
            this.pagination2.page = e
        },
        //正确分页回调
        pagination3Change(e) {
            this.pagination3.page = e
        },
        //错误分页回调
        pagination4Change(e) {
            this.pagination4.page = e
        },
        pagination2SizeChange(val) {
            this.pagination2.pagesize = val
        },
        pagination3SizeChange(val) {
            this.pagination3.pagesize = val
        },
        pagination4SizeChange(val) {
            this.pagination4.pagesize = val
        },
        //在线编辑分页回调
        pagination6Change(e) {
            this.pagination6.page = e
        },
        //获取运单库列表
        getBillList() {
            this.pagination1.loading = true
            let params = {
                pageIndex: this.pagination1.page,
                pageSize: this.pagination1.pagesize,
                taskSceneTypeID: this.searchForm.sceneID,
                importBatchID: this.searchForm.importBatchID,
                EnterpriseNamme: this.searchForm.EnterpriseNamme
            }
            getBillList(params).then(res => {
                if (res.data.DataList) {
                    this.tableList1 = res.data.DataList.map((item, index) => {
                        item.index = index + 1
                        return item
                    })
                } else {
                    this.tableList1 = []
                }
                this.pagination1.total = res.data.TotalCount
            }).finally(() => {
                this.pagination1.loading = false
            })
        },
        //查看所有运单
        openAllWaybill(item) {
            if (item.WayBillCount == 0) {
                this.$message.warning('暂无运单')
                return
            }
            this.currentItem = item
            this.flag.allWaybillDialog = true
            this.pagination2.loading = true
            let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination2.page,
                pageSize: 100000,
                statusList: [],
                driverIdCard: this.searchDriverForm.driverIdCard,
                driverPhone: this.searchDriverForm.driverPhone,
                driverName: this.searchDriverForm.driverName
            }
            gillsInfoDetails(params).then(res => {
                this.allWaybillList = res.data.DataList
                this.pagination2.total = res.data.TotalCount
                //处理成二维数组
                let arrLength = parseInt((Number(res.data.TotalCount) / this.pagination2.pagesize) + 1)
                for (let i = 0; i < arrLength; i++) {
                    this.currentAllWaybillSelectedList[i] = []
                }
            }).finally(() => {
                this.pagination2.loading = false
            })
        },
        //查看正确运单
        openRightWaybill(item) {
            if (item.CorrectBillCount == 0) {
                this.$message.warning('暂无运单')
                return
            }
            this.currentItem = item
            this.flag.rightWaybillDialog = true
            this.pagination3.loading = true
            let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination3.page,
                pageSize: 100000,
                statusList: [1, 6],
            }
            gillsInfoDetails(params).then(res => {
                this.rightWaybillList = res.data.DataList
                this.pagination3.total = res.data.TotalCount
                this.riskTime = (JSON.parse(res.data.Extend)).RiskTime
                //处理成二维数组
                let arrLength = parseInt((Number(res.data.TotalCount) / this.pagination3.pagesize) + 1)
                for (let i = 0; i < arrLength; i++) {
                    this.currentRightWaybillSelectedList[i] = []
                }
            }).finally(() => {
                this.pagination3.loading = false
            })
        },
        //查看错误运单
        openErrorWaybill(item) {
            if (item.ErrorBillCount == 0) {
                this.$message.warning('暂无运单')
                return
            }
            this.currentItem = item
            this.flag.errorWaybillDialog = true
            this.pagination4.loading = true
            let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination4.page,
                pageSize: 100000,
                statusList: [2, 4],
            }
            gillsInfoDetails(params).then(res => {
                this.errorWaybillList = res.data.DataList
                this.pagination4.total = res.data.TotalCount
                this.riskTime = (JSON.parse(res.data.Extend)).RiskTime
                //处理成二维数组
                let arrLength = parseInt((Number(res.data.TotalCount) / this.pagination4.pagesize) + 1)
                for (let i = 0; i < arrLength; i++) {
                    this.currentErrorWaybillSelectedList[i] = []
                }
            }).finally(() => {
                this.pagination4.loading = false
            })
        },
        //查看审核信息
        openAutioInfo(item) {
            this.currentItem = item
            this.flag.auditWaybillDialog = true
            let params = {
                importBatchId: this.currentItem.ImportBatchID
            }
            sumAuditInfo(params).then(res => {
                this.auditInfo = res.data
            })
        },
        //下载运单
        downloadWaybill(status) {
            let params = {
                importBatchId: this.currentItem.ImportBatchID
            }
            if (status) {
                params.status = status
            }
            downtBills(params).then(res => {
                location.href = res.down[0].pathName
            })
        },
        //打开车辆信息
        openVehicleInfo(item) {
            console.log(item)
            this.flag.dialogLoading = true
            let params = {
                CarNumber: item.CarNumber || item.LicensePlate,
            }
            let data = {}
            getCarInfo(params).then(res => {
                data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
                this.vehicleInfo = data
                this.flag.showVehicleInfo = true
            }).finally(() => {
                this.flag.dialogLoading = false
            })
        },
        //打开地图
        openMap(item) {
            this.flag.dialogLoading = true
            let params = {
                wayBillId: item.WaybillNumber,
                searchWord: item.CarNumber || item.LicensePlate,
                stDate: item.LoadingDatetime || item.Loading_time,
                edDate: item.SignDatetime || item.Submission_time,
                coordType: 2
            }
            getMapPath({ json: JSON.stringify(params) }).then(res => {
                this.mapList = res.Track
                this.flag.showMap = true
            }).finally(() => {
                this.flag.dialogLoading = false
            })
        },
        //查看操作员信息
        openDerverInfo(UserID) {
            this.flag.dialogLoading = true
            let data = {}
            getDriverDetails({ UserID: UserID }).then(res => {
                data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
                this.driverInfo = data
                this.flag.showDriverInfo = true
            }).finally(() => {
                this.flag.dialogLoading = false
            })
        },
        //这个方法是为了给表头加上这个类名  更改样式去掉全选功能
        cellClass(row) {
            if (row.columnIndex === 0) {
                return 'DisableSelection'
            }
        },
        //处理正确的表格选中变化
        currentRightWaybillSelectedChange(e, row) {
            this.currentRightWaybillSelectedList[this.pagination3.page - 1] = e
            let count = 0
            let arr = []
            //当前组合包发票总面额
            this.currentRightWaybillSelectedList.forEach(item => {
                item.forEach(el => {
                    count = count + 1
                    arr = arr.concat(el)
                })
            })
            this.currentRightWaybillSelectedCount = count //数量
            sumMoney({ wayBillId: arr }).then(res => {
                this.currentRightWaybillAmount = res.money //工单费
            })
        },
        //处理错误的表格选中变化
        currentErrorWaybillSelectedChange(e, row) {
            this.currentErrorWaybillSelectedList[this.pagination4.page - 1] = e
        },
        //处理编辑的表格选中变化
        currentEditWaybillSelectedChange({ records }) {
            this.currentEditWaybillSelectedList[this.pagination6.page - 1] = records
        },
        //处理所有的表格选中变化
        currentAllWaybillSelectedChange(e, row) {
            this.currentAllWaybillSelectedList[this.pagination2.page - 1] = e
        },
    },
    created() {
        // 获取业务类型
        getTask({}).then(res => {
            this.tasklist = this.tasklist.concat(res.TaskTypeList);
        })
        //获取运单库列表
        this.getBillList()
    },
    computed: {
        currentEditWaybillList() { //编辑数据
            this.$nextTick(() => {
                if (this.currentEditWaybillSelectedList.length != 0 && this.currentEditWaybillSelectedList[this.pagination6.page - 1].length != 0) {
                    this.currentEditWaybillSelectedList[this.pagination6.page - 1].forEach(item => {
                        this.editWaybillList.forEach(el => {
                            if (el.WaybillNumber === item.WaybillNumber) {
                                this.$refs.editWaybillRef.setCheckboxRow(el, true)
                            }
                        })
                    })
                }
            })
            return this.editWaybillList.slice((this.pagination6.pagesize * (this.pagination6.page - 1)), (this.pagination6.pagesize * this.pagination6.page))
        },
        currentRightWaybillList() { //正确数据
            //选中表格
            this.$nextTick(() => {
                if (this.currentRightWaybillSelectedList.length != 0 && this.currentRightWaybillSelectedList[this.pagination3.page - 1].length != 0) {
                    this.currentRightWaybillSelectedList[this.pagination3.page - 1].forEach(item => {
                        this.rightWaybillList.forEach(el => {
                            if (el.WaybillNumber === item.WaybillNumber) {
                                this.$refs.rightWaybillRef.toggleRowSelection(el, true)
                            }
                        })
                    })
                }
            })
            return this.rightWaybillList.slice((this.pagination3.pagesize * (this.pagination3.page - 1)), (this.pagination3.pagesize * this.pagination3.page))
        },
        currentErrorWaybillList() { //错误数据
            //选中表格
            this.$nextTick(() => {
                if (this.currentErrorWaybillSelectedList.length != 0 && this.currentErrorWaybillSelectedList[this.pagination4.page - 1].length != 0) {
                    this.currentErrorWaybillSelectedList[this.pagination4.page - 1].forEach(item => {
                        this.errorWaybillList.forEach(el => {
                            if (el.WaybillNumber === item.WaybillNumber) {
                                this.$refs.errorWaybillRef.toggleRowSelection(el, true)
                            }
                        })
                    })
                }
            })
            return this.errorWaybillList.slice((this.pagination4.pagesize * (this.pagination4.page - 1)), (this.pagination4.pagesize * this.pagination4.page))
        },
        currentAllWaybillList() { //所有运单数据
            //选中表格
            this.$nextTick(() => {
                if (this.currentAllWaybillSelectedList.length != 0 && this.currentAllWaybillSelectedList[this.pagination2.page - 1].length != 0) {
                    this.currentAllWaybillSelectedList[this.pagination2.page - 1].forEach(item => {
                        this.allWaybillList.forEach(el => {
                            if (el.WaybillNumber === item.WaybillNumber) {
                                this.$refs.allWaybillRef.toggleRowSelection(el, true)
                            }
                        })
                    })
                }
            })
            return this.allWaybillList.slice((this.pagination2.pagesize * (this.pagination2.page - 1)), (this.pagination2.pagesize * this.pagination2.page))
        },

    },
    watch: {
        'flag.allWaybillDialog'(val) {
            if (!val) {
                this.pagination2.page = 1
            }
        },
        'flag.rightWaybillDialog'(val) {
            if (!val) {
                this.pagination3.page = 1
                this.currentRightWaybillSelectedList = []
                this.currentRightWaybillSelectedCount = 0
                this.currentRightWaybillAmount = 0
            }
        },
        'flag.errorWaybillDialog'(val) {
            if (!val) {
                this.pagination4.page = 1
            }
        },
        'flag.editWaybillDialog'(val) {
            if (!val) {
                this.editWaybillList = []
                //编辑完后刷新错误运单列表
                let timer = setTimeout(() => {
                    this.openErrorWaybill(this.currentItem)
                    clearTimeout(timer)
                }, 500)
            }
        },
        'flag.voucherTabActive'(val) {
            //综合类、回收站
            if (val === "37d3fa70-13b3-4fac-b112-6877fb6ee213" || val === "8dbb03c6-7274-49b8-934e-23f3408f37f1") {
                this.voucherList.forEach(item => {
                    if (item.ID === val) {
                        this.currentVoucherObj = item
                    }
                })
            }
        }
    },
    components: {
        TXmap,
        VehicleInfo,
        DriverInfo
    },
}
</script>

<style lang="scss" scoped>
.waybillRepositoryPage {
    .riskVerify {
        height: calc(100vh - 150px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: white;

        .desc {
            font-size: 30px;
            font-weight: 700;
            margin-top: 30px;
        }
    }
}

.voucherWrap {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    width: 1200px;

    .item {
        width: 19%;
        margin: 16px 0;
        position: relative;

        .delete {
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            z-index: 9999;
        }

        p {
            width: 200px;
            color: blue;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.voucherDialog {
    .activeTab {
        background: #409eff;
        color: white;
    }

    .hasContent {
        position: relative;
    }

    .hasContent::after {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #f56c6c;
    }
}
</style>
<style>
.table-content .el-table {
    position: absolute;
    width: auto;
    max-width: none
}

.vux-table-col-red {
    background: #f56c6c;
    color: #fff;
}

.vxe-table--tooltip-wrapper {
    z-index: 999999 !important;
}
</style>